var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed inset-0 overflow-hidden add-new-link"},[_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.add)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('section',{staticClass:"absolute inset-y-0 right-0 pl-10 max-w-full flex"},[_c('div',{staticClass:"w-screen max-w-lg"},[_c('div',{staticClass:"h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"},[_c('div',{staticClass:"min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"},[_c('header',{staticClass:"py-6 px-8 bg-gray-100"},[_c('div',[_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg leading-6 font-normal text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("add-new-link"))+" ")])]),_c('div',{staticClass:"h-7 flex items-center absolute top-5 right-5",on:{"click":function($event){return _vm.$emit('close')}}},[_c('button',{staticClass:"text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none",attrs:{"aria-label":"Close panel"}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 18L18 6M6 6l12 12","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2"}})])])])])]),_c('div',{staticClass:"relative flex-1 mt-0 sm:p-0 p-6"},[_c('div',[_c('div',{staticClass:"sm:px-0 pt-8"},[_c('dl',[_c('div',{staticClass:"sm:px-8"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("link-name"))+" "),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v("*")])]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"link_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"placeholder":_vm.$t('link-name')},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("Description"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"placeholder":_vm.$t('Description')},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("Type"))+" "),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v("*")])]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.type,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"reduce":function (item) { return item.value; },"label":"display_name"},on:{"input":function($event){return _vm.updateCompany(_vm.form.target_type)}},model:{value:(_vm.form.target_type),callback:function ($$v) {_vm.$set(_vm.form, "target_type", $$v)},expression:"form.target_type"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),(_vm.whitelabel === 'Promena')?_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("Company"))+" "),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v("*")])]),(_vm.get_tracking_links_whitelabel.length > 0)?_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.trackingLinkWhitelabel,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"reduce":function (item) { return item.value; },"label":"display_name"},on:{"input":function($event){return _vm.updateURL(_vm.form.whitelabel)}},model:{value:(_vm.form.whitelabel),callback:function ($$v) {_vm.$set(_vm.form, "whitelabel", $$v)},expression:"form.whitelabel"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('div',{staticClass:"flex mb-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-5 w-5 text-blue-400",attrs:{"aria-hidden":"true","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"clip-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","fill-rule":"evenodd"}})])]),_c('div',{staticClass:"ml-3 flex-1 md:flex md:justify-between"},[_c('p',{staticClass:"text-sm text-blue-700"},[_vm._v(" "+_vm._s(_vm.$t("enter-full-url-to-the-target-site"))+" ")])])]),_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("target-url"))+" "),_c('span',{staticClass:"text-sm text-red-600 h-4"})]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[_c('ValidationProvider',{attrs:{"name":"target_url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.url),expression:"form.url"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"placeholder":_vm.$t('target-url'),"type":"url"},domProps:{"value":(_vm.form.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "url", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])])])]),_c('div',{staticClass:"flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm ml-3"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",attrs:{"type":"reset"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])]),_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("create-link"))+" ")])],1)])])])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed inset-0 transition-opacity"},[_c('div',{staticClass:"absolute inset-0 bg-black opacity-50"})])}]

export { render, staticRenderFns }